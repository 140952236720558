body img, body svg {
    display: inline;
}

.slick-next:before ,
    .slick-prev:before {
    content: "";
}


::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #626263;
  }
  
  ::-webkit-scrollbar {
    width: 8px;
    height: 6px;
    background-color: #626263;
  }
  
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #000;
  }
  